/* General styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.overlay-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 16px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    border-radius: 8px;
    max-width: 800px;
    margin: 20px auto;
}


/* Individual grid items */
.grid-item {
    background: rgba(255, 255, 255, 0.1);
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    font-size: 10px;
    display: block;  /* Stack h2 and h4 vertically */
    text-transform: uppercase;
}

.grid-item-two-span {
    background: rgba(255, 255, 255, 0.1);
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    font-size: 10px;
    grid-column: span 2;
    display: block;  /* Stack h2 and h4 vertically */
}

.grid-header {
    background: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    text-transform: uppercase;
}

.group-title {
    background: rgba(255, 255, 255, 0.2);
    font-weight: bold;
    text-transform: uppercase;
    grid-column: span 2;
    text-align: left;
    padding-left: 16px;
}

/* Ensure h2 and h4 are stacked with a bit of space between */
.grid-item h2, .grid-item h3, .grid-item h4 {
    margin: 0;
    padding: 4px 0;
}

.interaction-list {
    list-style-type: none;
    padding: 0;
    height: 200px; /* Set a fixed height for the scrollable area */
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: thin;
    scrollbar-color: rgba(40, 101, 243, 0.7);
  }
  
  .interaction-item {
    margin-bottom: 8px;
    color: white;
    padding: 4px;
    border-bottom: 1px solid #ccc;
    background-color: #ff00003d; /* Optional: Background for each item */
    border-radius: 4px; /* Optional: Rounded corners for items */
  }
  
  .interaction-item:nth-child(odd) {
    color: white;
    background-color: #007bff3e; /* Optional: Alternate background color */
  }

/* Responsive tweaks */
@media (max-width: 600px) {
    .overlay-container {
        grid-template-columns: 1fr;
    }
}

.documents-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  margin: 10px auto;
}

.document-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #d7d7d7;
  border-radius: 4px;
  box-shadow: 3px 5px #3e397e61;
  padding: 5px;
  background-color: #949494;
  text-align: center;
  max-width: 100px;
  min-height: 120px;
  margin: 5px;
  overflow: hidden;
}

.document-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  height: 100%;
}

.document-container h3 {
  font-size: 1rem; /* Adjust font size */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.document-container .eScore {
  font-size: 0.9rem; /* Adjust font size */
}

@media (max-width: 1024px) {
  .document-container {
    flex: 0 1 calc(20% - 50px); /* Adjust for 3 columns layout */
  }
}

@media (max-width: 768px) {
  .document-container {
    flex: 0 1 calc(25% - 20px); /* Full width on smaller screens */
  }
}

@media (max-width: 480px) {
  .document-container {
    flex: 0 1 calc(30% - 20px); /* Full width for very small screens */
    
  }
}

.docCover{
  object-fit: cover;
}

.noDocs {
  color: #f8f9fa;
  font-size: 22px;
}

.tab {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 10px solid transparent; 
  border-left: 10px solid #4152649b; 
  z-index: 10; /* Place the tab above the card content */
}

.Previous-Resume-Work{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100vh;
}

.documentTitle {
  color: rgb(255, 255, 255);
}

.keywords {
  width: 35vw;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #f8f9fa;
}
.keyTitles {
  text-align: center;
}

.ResJDKeywords{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
/* Menu Container */
.PYS-Menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Adjust height as needed */
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #333; /* Adjust text color */
  width: 100%;
}

.PYS-Tasks,
.PYS-Transaction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  font-size: 1rem;
}
.PYS-Tasks + hr {
  width: 80%; /* Width of HR */
  margin-top: 10px;
  margin-bottom: 20px;
}

/* Header Title and Tagline */
.PYS-header-title {
  font-size: 3rem; /* Adjust font size */
  margin-bottom: 20px;
}

.PYS-tagLine {
  font-size: 1.5rem; /* Adjust font size */
  margin-bottom: 20px;
}

/* Task Menu and Transaction Details */
.PYS-Task-Menu,
.PYS-TransactionNumber {
  font-size: 1rem; /* Adjust font size */
  margin-bottom: 10px;
}

/* Transaction Section */
.PYS-Transaction {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 90%;
  word-break: break-all;
}

/* Receipt Buttons */
.PYS-ReceiptButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
}

/* Buttons */
.PYS-Menu-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; /* Adjust font size */
  margin-top: 10px;
}

.PYS-Menu-container button:hover {
  background-color: #0056b3;
}

/* Continuing On Section */
.PYS-ContinuingOn {
  margin-top: 20px;
}

/* Button Holder */
.PYS-BTN-HLDR {
  display: flex;
  flex-direction: row; /* Adjust direction */
  align-items: center;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 767px) {
  .PYS-BTN-HLDR {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
  }

  .PYS-TransactionNumber {
    font-size: 0.8rem;
  }
}

/* Password Reset Container */
.PWRS-password-reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Adjust to full viewport height */
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #e9e9e9; /* Adjust text color */
}

/* Header Container */
.PWRS-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Header Title and Tagline */
.PWRS-PassWord-header-title {
  font-size: 3rem; /* Adjust font size */
  margin-bottom: 20px;
}

.PWRS-tagline {
  font-size: 1.5rem; /* Adjust font size */
  margin-bottom: 20px;
}

/* Header Subtitle */
.PWRS-header-subtitle {
  font-size: 2rem; /* Adjust font size */
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Password Reset Form */
.PWRS-prform {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%; /* Responsive width */
  max-width: 500px; /* Max width for larger screens */
  margin-bottom: 20px;
}

/* Input Fields */
.PWRS-prinput {
  width: 100%; /* Full width */
  margin-bottom: 1rem;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Submit Button */
.PWRS-prbutton {
  width: 25%; /* Full width */
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.PWRS-prbutton:hover {
  background-color: #0056b3;
}

/* Error Message and Link Text */
.PWRS-error-message {
  color: red;
  margin-bottom: 10px;
}

.PWRS-link-text {
  margin-top: 20px;
  color: #e9e9e9; /* Adjust text color */
  font-size: 1rem; /* Adjust font size */
}

.PWRS-LogLink {
  color: #f7e14f; /* Link color */
  text-decoration: underline; /* Underline to distinguish links */
}

.PWRS-LogLink:hover {
  color: #f69d29; /* Color on hover/focus */
}

/* Links Section */
.PWRS-links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between links */
  margin-top: 20px;
}

/* Link Buttons */
.PWRS-link-button {
  background: none;
  border: none;
  color: #007bff; /* Link color */
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
}

.PWRS-link-button:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Responsive Design */
@media (min-width: 768px) {
  .PWRS-prform {
    width: 50%; /* Wider form for larger screens */
  }
}

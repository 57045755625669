.CTaG-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
}

.CTaG-WRG-Select {
  background-color: #2179b8;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: Left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

.CTaG-DesiredRoleInput {
  width: 50%;
  margin-bottom: 1rem;
  margin-right: 90px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.CTaG-Milestones {
  width: 80%;
}

CTaG-UnorderedList {
  width: 70%;
}

.CTaG-ListItem {
  width: 90%;
  margin-bottom: 25px;
}

@media (max-width: 787px) {
  .CTaG-WRG-Select {
    width: 70%;
  }
}

@media(min-width: 768px){
  .CTaG-DesiredRoleInput {
    width: 80%;
  }
}

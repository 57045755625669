/* Menu Container */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  
}

.iframe {
  object-fit: cover;  /* Or 'contain', depending on your needs */
}

.VB-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
}

/* Header Title */
.VB-header-title {
  font-size: 3rem; /* Adjust font size */
  margin-top: -35px;
  margin-bottom: 75px;
  margin-left: 935px;
  z-index: 10;
  color:rgb(137, 119, 119);
  border:5px solid rgb(137, 119, 119);
  border-style: solid;
  border-radius: 15px; /* Rounded corners */
  padding: 5px 55px 7px 7px;
}

/* Voice Bot Container */
.VB-VoiceBot-container {
  width: 200%; /* Adjust width as needed */
  height: 300px;
  transform: scale(2.5);
  translate: -45px;
  margin-top: 130px;
}

.VB-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
}

.SMD {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align items at the top */
  margin-top: 200px; /* Space between title and main content */
  z-index: 10; /* Increase the z-index */
}

.recording-controls, .audio-delay-controls{
  z-index: 10;
}

.main-container {
  display: flex; /* Align video/input and VoiceBot side by side */
  align-items: flex-start; /* Align items at the top */
  margin-top: 20px; /* Space between title and main content */
}

.no-scroll {
  overflow: hidden;
}

.ChatModule{
  width: 100%;
  height: 40vh;
  overflow: hidden;
  position: relative;
  margin: 50px 0px;
}

.video-input-container {
  display: flex;
  top: -180px;
  left: -38px;
  flex-direction: column; /* Stack video and input vertically */
  margin-right: 33px; /* Space between video/input and VoiceBot */
  position: relative; /* Required for z-index to work */
  z-index: 10; /* Increase the z-index */
  border: none;
  padding: 10px; /* Optional: Add padding for better spacing */
  border-radius: 5px; /* Optional: Add rounded corners */
  background-color: rgba(59, 203, 49, 0.277); /* Dimmer background color */
  box-shadow: 0 0 50px rgba(255, 255, 214, 0.5);
}

.playlist-container {
  display: flex;
  flex-direction: column; /* Stack video and input vertically */
  position: relative; /* Required for z-index to work */
  z-index: 10; /* Increase the z-index */
  border: none;
  padding: 10px; /* Optional: Add padding for better spacing */
  border-radius: 5px; /* Optional: Add rounded corners */
  background-color: rgba(0, 0, 0, 0.249); /* Dimmer background color */
  height: 155px; /* Maximum height for the playlist */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 425px; /* Set a fixed width for the playlist */
  /* Optional: Add some transition for smoother appearance */
  transition: all 0.3s ease;
}

.info-box pre {
  font-family: inherit;   /* Inherit the font-family from parent element */
  color: inherit;         /* Inherit the text color from parent */
  white-space: pre-wrap;  /* Allow text to wrap if it's too long */
  word-wrap: break-word;  /* Allow words to break onto the next line */
}

.transcript-line:hover {
  color: #00796b; /* Darker shade on hover */
  text-decoration: underline;
}

.tabs{
  position: sticky;
  top: 0px;
  width: 550px;
  padding: 5px 0px;
  transform: translateX(-10px);
  z-index: 2000;
}

.playlist-title {
  position: sticky; /* Keep it sticky within its container */
  top: 0; /* Position it at the top of the container */
  background-color: rgba(0, 0, 0, 0.8); /* Optional: Background color to contrast with content */
  padding: 5px; /* Optional: Padding for aesthetics */
  z-index: 20; /* Ensure it stays above other content */
  transform: translateX(-10px); /* Start slightly above */
}

.playlist-list {
  opacity: 0; /* Start invisible */
  transform: translateY(-10px); /* Start slightly above */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Update keyframes for fading in/out from the right */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-2000px); /* Start 20px to the right */
  }
  to {
    opacity: 1;
    transform: translateX(0); /* End at the normal position */
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0); /* Start at normal position */
  }
  to {
    opacity: 0;
    transform: translateX(-2000px); /* Move 20px to the right */
  }
}

/* User Controls */
.VB-UserControls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

/* Speech Recognition Buttons */
.VB-SpeechRecButtons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

/* User Speech Textarea */
.VB-userSpeech {
  width: 95%;
  height: 150px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.info-box {
  z-index: 10;
  max-height: 70%; /* Set max height for the info box */
  overflow-y: auto; /* Allow vertical scrolling */
  overflow-x: hidden; /* Allow vertical scrolling */
  scroll-behavior: smooth; /* Optional smooth scrolling */
  padding: 10px; /* Optional padding */
  scroll-behavior: smooth; /* Optional smooth scrolling */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Transition for both opacity and transform */
  border: 2px solid #007bff; /* Adjust border color */
  box-shadow: 10px 10px 30px rgb(255, 255, 214);
}

.fade-in {
  animation: fadeIn 0.5s ease-out forwards; /* Apply fadeIn animation */
}

.fade-out {
  animation: fadeOut 0.5s ease-in forwards; /* Apply fadeOut animation */
}

.playlist-container ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.playlist-container li {
  padding: 10px; /* Add padding for each video title */
  cursor: pointer; /* Ensures the cursor changes to pointer */
  color: grey; /* Keep your existing color */
  transition: background-color 0.3s; /* Smooth background color transition */
}

.playlist-container li:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Highlight on hover */
}

.transcript-container{
  z-index: 15; /* Ensure it appears above other content */
}

.playlist-container, .info-box, .OverLay::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2); /* Background of the scrollbar track */
  border-radius: 5px; /* Rounded corners for the track */
}

/* For Firefox */
.playlist-container, .info-box, .OverLay {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: rgba(40, 101, 243, 0.7) rgba(0, 0, 0, 0.2); /* Thumb and track colors */
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(5, 2fr); /* 3 columns */
  gap: 5px; /* Space between buttons */
  position: fixed; /* Position it in the corner */
  bottom: 10px; /* Distance from the bottom */
  left: 745px; /* Shifted 50px to the left (originally 20px) */
  z-index: 10; /* Ensure it is above other content */
}

.button-grid2 {
  display: grid;
  grid-template-columns: repeat(2, 2fr); /* 3 columns */
  gap: 616px; /* Space between buttons */
  position: fixed; /* Position it in the corner */
  bottom: 48px; /* Distance from the bottom */
  left: 673px; /* Shifted 50px to the left (originally 20px) */
  z-index: 10; /* Ensure it is above other content */
}

.info-box {
  opacity: 0; /* Start invisible */
  transform: translateY(20px); /* Start slightly below */
  transition: opacity 0.5s ease, transform 0.5s ease;
  z-index: 10;
}

.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Move to original position */
  z-index: 10;
}

.fade-out {
  opacity: 0; /* Fully invisible */
  transform: translateY(20px); /* Slide down slightly */
  z-index: 0;
}

.WordCloudDimensioner{
  display: flex;
  flex-direction: row; /* Stack input and button vertically */
  position: relative; /* Required for z-index to work */
  z-index: 10; /* Increase the z-index */
  border: none;
  border-radius: 5px; /* Optional: Add rounded corners */
  padding: 5px;
  margin-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.2491); 
}

.square-button {
  background-color: rgba(129, 86, 86, 0.55); /* Button color */
  color: white; /* Text color */
  border:2px solid rgb(137, 119, 119);
  border-style: solid;
  border-radius: 5px; /* Rounded corners */
  padding: 15px; /* Square padding */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Change cursor on hover */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  transition: background-color 0.3s; /* Smooth background transition */
}

.square-button2 {
  background-color: rgba(50, 139, 206, 0.55); /* Button color */
  color: white; /* Text color */
  border:2px solid rgb(137, 119, 119);
  border-style: solid;
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Square padding */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Change cursor on hover */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  transition: background-color 0.3s; /* Smooth background transition */
}

.square-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.youtube-iframe {
  width: 320px; /* Set the desired width */
  height: 180px; /* Set the desired height */
  margin-bottom: 10px; /* Space below the iframe */
  border: none; /* Remove the default border */
  position: relative; /* Required for z-index to work */
  z-index: 5; /* Increase the z-index */
}

.stagelights{
  z-index: 5;
}

.Dial{
  z-index: 9;
}

.monitor{
  z-index: 5;
  transform: scale(0.85);
}

.studiomonitor{
  transform: scale(1.1);
}

.bookshelf{
  transform: scale(1.55);
}

.plant1{
  transform: scale(.75);
}

.ComfyChair{
  transform: scale(.70);
}

.plant2{
  transform: scale(1.25);
  z-index: 6;
}

.studiomic{
  transform: scale(0.75);
}

.input-container {
  display: flex;
  flex-direction: row; /* Stack input and button vertically */
  position: relative; /* Required for z-index to work */
  z-index: 10; /* Increase the z-index */
  border: none;
  border-radius: 5px; /* Optional: Add rounded corners */
  padding: 5px;
  margin-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.2491); /* Optional: Set background color */
  width: 437px;
}

input[type="text"] {
  padding: 5px;
  margin-bottom: 5px; /* Space below the input */
}

.LearnerLevel{
  position: fixed; /* Position it in the corner */
  bottom: -10px; /* Distance from the bottom */
  left: 20px;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 10px;  /* Rounded corners */
  border: 2px solid #007bff;
  background-color: #f8f9fa;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.LowerControls{
  position: fixed;
  z-Index: 4000;
}

.LearnerLevel:hover {
  border-color: #0056b3;
}

.LearnerLevel:focus {
  border-color: rgb(137, 119, 119);
  box-shadow: 0 0 10px rgba(0, 86, 179, 0.4);
  outline: none;
}

.VidPlayButton, .VidAddButton {
  background-color: hsla(0, 0%, 48%, 0.55); /* Button color */
  color: white; /* Text color */
  border:2px solid rgb(137, 119, 119);
  border-style: solid;
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth background transition */
  padding: 10px 10px; /* Adjust padding for size */
  font-size: 14px; /* Set font size */
  cursor: pointer; /* Change cursor to pointer */
  margin-top: 0px; /* Space above the button */
  margin-bottom: 5px;
  width: 60px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

.OverLay{
  z-Index: 100;
  position: absolute;
  top: 20%;
  left: 75%;
  width: 25%; 
  height: 75%;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 10px;  /* Rounded corners */
  overflow-y: auto; /* Allow vertical scrolling */
  overflow-x: hidden; /* Allow vertical scrolling */
  scroll-behavior: smooth; /* Optional smooth scrolling */
  border: 2px solid #007bff; /* Adjust border color */
  box-shadow: 10px 10px 30px rgb(255, 255, 214);
}

/* Main FluencyTrainer container */
.FluencyTrainer {
  position: absolute;
  background-color: transparent;
  border-radius: 15px;
  box-shadow: none; /* Remove shadow for a cleaner, sleeker look */
  padding: 5px; /* Reduced padding */
  width: 80%; /* Width of the container */
  max-width: 600px; /* Max width */
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2px; /* Reduced gap between elements */
  height: 1.5vh; /* 2/3 smaller than before for even smaller height */
  justify-content: space-between;
  align-items: center;
  z-index: 15;
  bottom: 28.5%;
  left: 10%;
}

/* Video Controls Section */
.vidControls {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 5px; /* Smaller gap between buttons */
  width: 100%; /* Full width of the container */
}

.vidControls button {
  background-color: transparent;
  color: white;
  border-radius: 6px;
  padding: 6px; /* Reduced padding for smaller buttons */
  font-size: 10px; /* Much smaller font size */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.vidControls button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.vidControls button:active {
  transform: scale(0.98);
}

/* Trial Selection Section */
.TrialSelect {
  display: flex;
  justify-content: center;
  gap: 5px; /* Smaller gap between buttons */
  width: 100%; /* Full width of the container */
}

.TrialSelect button {
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 6px 12px; /* Reduced padding for smaller buttons */
  font-size: 10px; /* Much smaller font size */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.TrialSelect button:hover {
  background-color: #4682b4;
  transform: scale(1.05);
}

.TrialSelect button:active {
  transform: scale(0.98);
}

.icon {
  position: relative;
  background: rgba(124, 174, 255, 0.173);
  cursor: pointer;
  margin: auto;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  height: 150px;
  width: 150px;
  line-height: 150px;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  transition: 0.24s 0.2s;
}

.icon:hover {
  background: rgba(rgb(255, 255, 255), 0.75);
}

.menu {
  position: absolute;
  top: -75px;
  left: -75px;
  border: 150px solid transparent;
  cursor: default;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 1.4s 0.07s;
  z-index: -5;
}

.spread {
  position: absolute;
  top: -100px;
  left: -100px;
  transform-origin: 100px 100px;
  transition: all 0.5s 0.1s;
}

/* Handle hover state for spreading the items */
.icon:hover .menu {
  transition: transform 0.4s 0.08s, z-index 0s 0.5s;
  transform: scale(1);
  z-index: 5;
}

.icon:hover .spread,
.icon:hover .unit {
  transition: all 0.6s;
}

/* Update rotation logic for 12 items */
.menu .spread:nth-child(1) {
  transition-delay: 0.02s;
  transform: rotate(0deg);
}

.menu .spread:nth-child(1) .unit {
  transition-delay: 0.04s;
  transform: rotate(0deg);
}

.menu .spread:nth-child(2) {
  transition-delay: 0.04s;
  transform: rotate(30deg);
}

.menu .spread:nth-child(2) .unit {
  transition-delay: 0.08s;
  transform: rotate(-30deg);
}

.menu .spread:nth-child(3) {
  transition-delay: 0.06s;
  transform: rotate(60deg);
}

.menu .spread:nth-child(3) .unit {
  transition-delay: 0.12s;
  transform: rotate(-60deg);
}

.menu .spread:nth-child(4) {
  transition-delay: 0.08s;
  transform: rotate(90deg);
}

.menu .spread:nth-child(4) .unit {
  transition-delay: 0.16s;
  transform: rotate(-90deg);
}

.menu .spread:nth-child(5) {
  transition-delay: 0.10s;
  transform: rotate(120deg);
}

.menu .spread:nth-child(5) .unit {
  transition-delay: 0.20s;
  transform: rotate(-120deg);
}

.menu .spread:nth-child(6) {
  transition-delay: 0.12s;
  transform: rotate(150deg);
}

.menu .spread:nth-child(6) .unit {
  transition-delay: 0.24s;
  transform: rotate(-150deg);
}

.menu .spread:nth-child(7) {
  transition-delay: 0.14s;
  transform: rotate(180deg);
}

.menu .spread:nth-child(7) .unit {
  transition-delay: 0.28s;
  transform: rotate(-180deg);
}

.menu .spread:nth-child(8) {
  transition-delay: 0.16s;
  transform: rotate(210deg);
}

.menu .spread:nth-child(8) .unit {
  transition-delay: 0.32s;
  transform: rotate(-210deg);
}

.menu .spread:nth-child(9) {
  transition-delay: 0.18s;
  transform: rotate(240deg);
}

.menu .spread:nth-child(9) .unit {
  transition-delay: 0.36s;
  transform: rotate(-240deg);
}

.menu .spread:nth-child(10) {
  transition-delay: 0.20s;
  transform: rotate(270deg);
}

.menu .spread:nth-child(10) .unit {
  transition-delay: 0.40s;
  transform: rotate(-270deg);
}

.menu .spread:nth-child(11) {
  transition-delay: 0.22s;
  transform: rotate(300deg);
}

.menu .spread:nth-child(11) .unit {
  transition-delay: 0.44s;
  transform: rotate(-300deg);
}

.menu .spread:nth-child(12) {
  transition-delay: 0.24s;
  transform: rotate(330deg);
}

.menu .spread:nth-child(12) .unit {
  transition-delay: 0.48s;
  transform: rotate(-330deg);
}

.unit {
  position: absolute;
  background: rgba(91, 194, 239, 0.786);
  font-size: 100%;
  text-decoration: none;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  transition: 0.6s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

ul, li {
  margin: 0;
  outline: 0;
  padding: 0;
  list-style: none;
}

.icon {
  top: -50%;
  margin-top: -194px; /* Adjust for center alignment */
  left: 155%;
}

.TwoVCombosModalButton1 {
  position: absolute;  /* Absolute positioning relative to the parent container */
  top: 15%;
  right: 8%;
  width: 35px;
  z-index: 2006;  /* Button on top of everything */
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}


.TCOBContainer2 {
  position: fixed;  /* Change to fixed so it stays fixed while scrolling */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background: rgba(0, 0, 0, 0.5);  /* Semi-transparent background to overlay everything */
  z-index: 1000;  /* Ensure it appears above other content */
  display: flex;
  justify-content: center;
  align-items: center;  /* Center the modal content */
}

.TwoVCombosContainer2 {
  display: flex;
  flex-direction: row;
  position: relative;  /* Keeps the container in its place */
  justify-content: center;  /* Horizontally centers content */
  align-items:end;  /* Vertically centers content */
  width: 95%; 
  height: 90%;  /* Adjusted height for better responsiveness */
  padding: 20px;
  font-size: 16px;
  border-radius: 10px;
  overflow-y: hidden;  /* Enable vertical scrolling if content overflows */
  border: 2px solid #007bff;
  box-shadow: 10px 10px 30px rgb(255, 255, 214);
  background: rgba(38, 38, 39, 0.952);
  z-index: 1005;  /* Positioned below the button */
}


.TwoVCombosModalButton2 {
  position: absolute;  /* Absolute positioning relative to the parent container */
  top: 6%;
  right: 3.5%;
  width: 35px;
  z-index: 1006;  /* Button on top of everything */
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.TwoVCombosModalButton2:hover {
  background-color: #0056b3;
}

.VisemeClearButton{
  font-size: 10px;
}

.SelectVids{
  margin-left: 2%;
  width: 93%;
}

.V3DContainer {
  display: flex;
  justify-content: center;
  align-items: center;  /* Center the modal content */
  position: fixed;  /* Change to fixed so it stays fixed while scrolling */
  overflow-y: hidden;  /* Enable vertical scrolling if content overflows */
  top: 30px;
  
}

.Viseme3D2 {
  display: flex;
  position: relative;  /* Absolute positioning relative to the parent container */
  left: -30px;
  font-size: 16px;
  overflow-y: hidden;  /* Enable vertical scrolling if content overflows */
  overflow-x: auto;
  
 }

/* Responsive styling for smaller screens */
@media (max-width: 600px) {
  .FluencyTrainer {
    width: 90%; /* Width of the container on smaller screens */
    padding: 5px;
  }

  .vidControls {
    grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
  }

  .TrialSelect {
    flex-direction: column; /* Stack trial buttons vertically */
    gap: 3px;
  }
}



/* Responsive Design for Larger Screens */
@media (min-width: 768px) {
  .VB-menu-header-title {
    font-size: 4rem; /* Larger font for larger screens */
  }
}


/* Terms of Service Container */
.cookie-policy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 20px;
    font-family: Arial, sans-serif; /* Choose your preferred font */
    color: #e9e9e9; /* Adjust text color */
    max-width: 90%; /* Set maximum container width */
    margin: auto; /* Center container */
  }
  
  /* Header Styling */
  .CPolicy-Header {
    font-size: 2.5rem; /* Adjust font size */
    margin-bottom: 20px;
    text-align: center;
  }
  
  .CPolicy-SubHeader{
    font-size: 1.75rem; /* Adjust font size */
    margin-bottom: 20px;
    text-align: center;
  }

  /* Paragraph Styling */
  .CPolicy-Paragraph {
    font-size: 1rem; /* Adjust font size */
    margin-bottom: 15px;
    text-align: justify;
    line-height: 1.5; /* Improve line spacing for readability */
    max-width: 800px; /* Limit max width for readability */
    width: 100%;
    margin-left: auto;
    margin-right: auto; /* Center content */
  }
  
  /* Unordered List Styling */
  .CPolicy-UnorderedList {
    list-style-type: disc;
    margin-left: 20px;
    color: rgb(126, 126, 126);
  }
  
  /* List Item Styling */
  .CPolicy-ListItem {
    margin-bottom: 10px;
  }
  
  /* Sub Header Styling */
  .CPolicy-ParaHeader {
    font-size: 1.25rem; /* Adjust font size */
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  /* Back to Login Button */
  .CPolicy-cta-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem; /* Adjust font size */
  }
  
  .CPolicy-cta-button:hover {
    background-color: #0056b3;
  }

  /* Links Section */
.CPolicy-links-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Space between buttons */
    margin-top: 20px;
    width: 70%;
  }
  
  /* Individual Link Buttons */
  .CPolicy-link-button {
    background: none;
    border: none;
    color: #007bff; /* Link color */
    font-size: 1rem;
    padding: 10px;
    cursor: point;
  }
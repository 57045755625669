/* Signup Container */
.SU-signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  margin-top: 50px;
  padding-top: 5vh; /* Adjust top padding */
  width: 100%;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #333; /* Adjust text color */
}

/* Signup Form */
.SU-SignUp-Input {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%; /* Responsive width */
  max-width: 400px;
  margin-bottom: 20px;
}



/* Input Fields */
.SU-SignUp-Input input {
  width: 95%; /* Full width */
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Submit Button */
.SU-SignUp-Input button {
  width: 35%; /* Full width */
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.SU-SignUp-Input button:hover {
  background-color: #0056b3;
}

/* Error Message */
.SU-error-message {
  color: red;
  margin-bottom: 10px;
}

/* Header Title and Tagline */
.SU-supheader-title {
  text-align: center;
  font-size: 3rem; /* Adjust font size */
  margin-top: 0;
  margin-bottom: 20px;
}

.SU-tagline {
  font-size: 1.5rem; /* Adjust font size */
  margin-bottom: 20px;
}

/* Login Link */
.SU-LogLink {
  color: #f7e14f; /* Bright color for visibility */
  text-decoration: underline; /* Underline to distinguish links */
}

.SU-LogLink:hover {
  color: #f69d29; /* Color on hover/focus */
  background-color: rgba(255, 255, 255, 0.1); /* Subtle background highlight */
}

/* Link Buttons */
.SU-links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
  width: 70%;
}

/* Link Buttons */
.SU-link-button {
  background: none;
  border: none;
  color: #007bff; /* Link color */
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
}

.SU-link-button:hover {
  text-decoration: underline; /* Underline on hover */
}

@media (max-width: 768px){
  input[type="email"]{
    width: 90%;
  }
}
.analysis-section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  margin: auto;
  padding: 10px;
}

.textarea-container {
  position: relative;
  width: 100%; /* Full width of the parent container */
  margin-bottom: 20px; 
}

.styled-textarea {
  width: 100%; /* Make textarea take the full width of its container */
  min-height: 200px; /* Adjust min-height as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
  margin-bottom: 10px;
}

.analysis-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.analysis-button:hover {
  background-color: #0056b3;
}


char-count-total, h5 {
  font-weight: bold;
  text-align: center;
  margin: 5px;
}

.MasterResume,.pullFromMaster, .AddtoMaster {
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .styled-textarea {
    min-height: 150px; /* Adjust for smaller screens */
  }

  .char-count, .char-count-left {
    font-size: 0.5em; /* Smaller font size for smaller screens */
  }
  h3, h4, h5 {
    font-size: 0.9em; /* Smaller font size for headings on smaller screens */
  }
}

.char-count-total {
  text-align: center;
}
.interviewTips {
  color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 375px;
  text-align: center;
  margin: 20px auto; /* Ensure margin on all sides for smaller screens */
  padding: 20px;
}

.tipContent {
  height: 150px; /* Make height flexible to accommodate content */
}

.tipHeader {
  height: 50px;
}

.interviewTipBucket {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.interviewTipButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Add margin for spacing */
}

.tipContent,
.tipHeader {
  max-width: 100%; /* Ensure content does not exceed container width */
  margin-bottom: 10px; /* Add some space below each section */
}

/* Responsive Adjustments for Smaller Screens */
@media (max-width: 768px) {
  .interviewTips {
    width: 75%;
    height: 400px;
  }
  .tipContent {
    height: 170px; /* Make height flexible to accommodate content */
  }

  .tipHeader {
    height: 50px;
  }
}

@media (min-width: 767px) {
  .IP-VoiceBot-container {
    width: 30%;
  }
}

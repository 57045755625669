/* Purchase Container */
.PCH-Purchase-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Adjust to full viewport height */
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #333; /* Adjust text color */
}

/* Header Title and Tagline */
.PCH-header-title {
  font-size: 3rem; /* Adjust font size */
  margin-bottom: 20px;
}

.PCH-tagline {
  font-size: 1.5rem; /* Adjust font size */
  margin-bottom: 20px;
}

/* Input for Token Number */
.PCH-TokenNum,
input[type="number"] {
  width: 10%; /* Adjust width as needed */
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem; /* Adjust font size */
}

/* Buttons */
.PCH-cta-button,
.PCH-menuGoer,
.PCH-link-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; /* Adjust font size */
  margin-top: 20px;
}

.PCH-cta-button:hover,
.PCH-menuGoer:hover,
.PCH-link-button:hover {
  background-color: #0056b3;
}

/* Under Construction Text */
.PCH-under-construction-text {
  font-size: 2rem; /* Adjust font size */
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Div Message */
.PCH-divMsg {
  width: 90%; /* Width adjustment */
  margin-bottom: 20px;
  font-size: 1.25rem; /* Adjust font size */
  color: white;
}

/* Links Section */
.PCH-links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
  width: 70%;
}

/* Link Buttons */
.PCH-link-button {
  background: none;
  border: none;
  color: #007bff; /* Link color */
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
}

.ButtonstoNav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Logout Nav and Custom Drawer */
.PCH-logout-nav {
  display: flex;
  justify-content: space-between; /* Or 'center' based on your design */
  align-items: center;
  position: fixed;
  top: 0;
  left: 2%;
  width: 100%;
  padding: 10px;
  z-index: 1000;
}

.PCH-custom-drawer .MuiDrawer-paper {
  color: white;
  background-color: rgba(13, 13, 13, 0.172);
}

/* Responsive Design */
@media (min-width: 767px) {
  .PCH-TokenNum,
  input[type="number"] {
    width: 3%; /* Full width for smaller screens */
  }
}

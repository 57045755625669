.Bpp {
  position: absolute; /* Ensure it has positioning */
  z-index: 10000; /* Keep it high */
  top: 0;
  left: 0;
}

.HexagonBackGround {
  position: fixed;
  top: 0;
  left: 0;
  width: 200vw; /* Full viewport width */
  height: 300vh; /* Full viewport height */
  z-index: -1; /* Place it behind other content */
}

.content-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: 90%;
}

:root {
  --text-color-mode-1: rgb(255, 255, 255); /* color for mode 1 */
  --text-color-mode-2: rgb(25, 25, 25); /* color for mode 2 */
}

pre,
h1,
h2,
h3,
h4,
h5,
p,
.CP-chat-message, 
.CP-coach-chat-message {
  color: var(--text-color); /* Use CSS variable */
}

@media (min-width: 768px) {
  .content-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    width: 100%;
  }

  .logoContainer {
    position: absolute;
    top: 50px;
    right: 5%;
    z-index: 100;
  }
}

/* Voice Bot and Chat Box Container */
.CP-Conversation-Practice-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CP-Main-Header-convo {
  padding-top: 50px;
}

.CP-Main-Header-convo,
.CP-Main-Header-Tagline {
  text-align: center;
  margin-bottom: 20px;
}

.CP-ConversationPartnersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.CP-VB-Chat-Session {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}
.CP-milestones-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  word-wrap: break-word;
}

.CP-Opener {
  text-align: center; /* Ensure text is centered horizontally */
  padding: 8px; /* Add vertical and horizontal padding */
  margin-top: 15px;
}

.CP-VoiceBot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.CP-chat-box,
.CP-coach-chat-box {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  transition: transform 0.3s, opacity 0.3s;
}

.CP-ConversationPartners {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  gap: 10px;
}

.CP-ChatBox-Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* Adjusting the layout for chat boxes */
.CP-chat-box,
.CP-coach-chat-box {
  display: flex;
  flex-direction: column-reverse;
  align-items: top;
  flex: 0 0 60%; /* Each box takes up 50% of the width */
  max-width: 33%; /* Ensures the box doesn't exceed 50% width */
  border: 1px solid #ccc;
  border-color: rgb(69, 201, 231);
  font-size: 18px;
  margin-left: 2px;
  display: none;
  min-height: 120px;
  max-height: 300px;
  overflow: auto;
}

/* Chat Messages Styling */
.CP-chat-message,
.CP-coach-chat-message {
  background-color: #f4f4f4;
  padding: 8px 12px;
  margin-bottom: 10px;
  border-radius: 4px;
  word-wrap: break-word;
}

.CP-chat-message {
  margin-top: 0%;
  font-size: 16px;
}

.CP-chat-message.Instructor {
  margin-top: 0%;
  width: 75%;
  margin-left: 2%;
  background-color: rgba(255, 255, 0, 0.284);
}

.CP-chat-message.user {
  margin-top: 0%;
  width: 75%;
  margin-left: 18%;
  background-color: rgba(0, 0, 255, 0.284);
}

.CP-chat-message.Partner {
  margin-top: 0%;
  width: 75%;
  margin-left: 4%;
  background-color: rgba(255, 0, 0, 0.284);
}

.CP-coach-chat-message {
  margin-top: 0%;
  background-color: rgba(0, 128, 0, 0.284);
  font-size: 16px;
}

.CP-CoreCasing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

/* Container to hold both chat boxes side by side */
.CP-Chat-Box-Container {
  display: flex;
  width: 100%;
}

.CP-ControlRecord {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

/* User Conversation Controls */
.CP-UserConvoControls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.CP-SelectorButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CP-SpeechRecButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 90%;
}

.CP-OpenforBusiness {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
}

textarea.CP-userConvoSpeech {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  height: 150px; /* Adjust height as needed */
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

/* Bottom Line and Logout Nav */
.CP-BottomLine,
.CP-logout-nav {
  margin-top: 20px;
  width: 100%;
}

/* Responsive Styles for screens larger than 768px */
@media screen and (min-width: 769px) {
  .CP-CoreCasing,
  .CP-VB-Chat-Session,
  .CP-ConversationPartners,
  .CP-SpeechRecButtons {
    flex-direction: row;
    align-items: flex-start;
  }

  .CP-chat-box,
  .CP-coach-chat-box {
    flex: 0 0 40%; /* Each box takes up 50% of the width */
    max-width: 50%; /* Ensures the box doesn't exceed 50% width */
    min-height: 270px;
    max-height: 270px;
    overflow: auto;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
  }

}
@media screen and (min-width: 769px) {
  .textarea.CP-userConvoSpeech {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    height: 70px; /* Adjust height as needed */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
  }

  .CP-CoreCasing {
    width: 100%;
  }
  .CP-milestones-Lesson {
    width: 200%;
    margin-left: 500px;
    margin-top: 20px;
  }

  .CP-milestones-container {
    width: 200%;
    margin-left: 100px;
  }

  .CP-milestones-unorderedList {
    padding: 10px;
    margin-left: 100px;
  }

  .CP-milestone-ListItem {
    margin-left: 450px;
  }
}

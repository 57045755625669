/* Account Deleted Container */
.ACCTDEL-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Adjust height as needed */
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #333; /* Adjust text color */
}

/* Header Title and Tagline */
.ACCTDEL-header-title {
  font-size: 3rem; /* Adjust font size */
  margin-bottom: 20px;
}

.ACCTDEL-tagline {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.ACCTDEL-Message-Title {
  font-size: 2rem;
}

.ACCTDEL-Message {
  font-size: 1.5rem;
}

/* Links Section */
.ACCTDEL-links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
  width: 70%;
}

/* Link Buttons */
.ACCTDEL-link-button {
  background: none;
  border: none;
  color: #007bff; /* Link color */
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
}

#wrapTools {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    translate: 0px;
  }
  
  #theBotTools {
    width: 500px;
    height: 500px;
    transform: scale(1.1);
    translate: -10px;
    overflow: hidden;
    margin: 20px 0px 20px 0px;
  }
  
  #VoiceBotToolsSetting {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
/* Terms of Service Container */
.TOS {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 20px;
    font-family: Arial, sans-serif; /* Choose your preferred font */
    color: #e9e9e9; /* Adjust text color */
    
    max-width: 1000px; /* Set maximum container width */
    margin: auto; /* Center container */
  }
  
  /* Header Styling */
  .TOS-Header {
    font-size: 2.5rem; /* Adjust font size */
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Paragraph Styling */
  .TOS-Paragraph {
    font-size: 1rem; /* Adjust font size */
    margin-bottom: 15px;
    text-align: justify;
    line-height: 1.5; /* Improve line spacing for readability */
    max-width: 800px; /* Limit max width for readability */
    width: 100%;
    margin-left: auto;
    margin-right: auto; /* Center content */
  }
  
  /* Unordered List Styling */
  .TOS-UnorderedList {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  /* List Item Styling */
  .TOS-ListItem {
    margin-bottom: 10px;
  }
  
  /* Sub Header Styling */
  .TOS-ParaHeader {
    font-size: 1.25rem; /* Adjust font size */
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  /* Back to Login Button */
  .TOS-cta-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem; /* Adjust font size */
  }
  
  .TOS-cta-button:hover {
    background-color: #0056b3;
  }
  
  /* Links Section */
  .TOS-links-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px; /* Space between links */
    margin-top: 30px;
  }
  
  .TOS-links-section button {
    background: none;
    border: none;
    color: #007bff; /* Link color */
    cursor: pointer;
    font-size: 1rem; /* Adjust font size */
  }
  
  .TOS-links-section button:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .TOS-Paragraph, .TOS-UnorderedList, .TOS-ListItem {
      width: 90%;
    }
  
    .TOS {
      max-width: 100%; /* Allow full width on smaller screens */
    }
  }
  
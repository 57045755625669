.sociogram-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%; /* Make sure the container takes up full width */
  }
  
  .sociogram-wrapper {
    flex-grow: 1;
  }
  
  .slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;  /* You can adjust this margin as needed */
    position: absolute;  /* Absolute positioning for fine control */
    top: 50%; /* Vertically center the slider */
    left: 60%; /* Move it left by 100px from the right edge */
    transform: translateY(-50%); /* Adjust vertical centering */
  }
  
  .vertical-slider {
    transform: rotate(-90deg);
    width: 300px;
    height: 20px;
    margin: 20px;
  }
  
/* Careers Container */
.CRS-careers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Adjust height as needed */
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #333; /* Adjust text color */
}

/* Career Opportunities Section */
.CRS-career-opportunities {
  margin-top: 2rem;
}

/* Header Title and Tagline */
.CRS-Careers-header-title {
  font-size: 3rem; /* Adjust font size */
  margin-bottom: 20px;
}

.CRS-tagline {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

/* Careers Hiring Header */
.CRS-CareersHiring {
  margin-top: 1rem; /* Adjust margin */
  font-size: 2rem; /* Adjust font size */
}

/* Career Item Styling */
.CRS-career {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

/* HR Styling */
.CRS-career + hr {
  width: 80%; /* Width of HR */
  border: none;
  height: 1px;
  background-color: #ccc; /* HR Color */
  margin-top: 10px;
  margin-bottom: 20px;
}

/* Career Titles and Descriptions */
.CRS-career h3 {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

.CRS-career p {
  font-size: 1.25rem;
  color: white;
}

/* Inquiries Section */
.CRS-inquiries {
  font-size: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: white;
}

.CRS-inquiries a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.CRS-inquiries a:hover {
  color: #0056b3;
}

/* Call to Action Button */
.CRS-cta-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; /* Adjust font size as needed */
}

.CRS-cta-button:hover {
  background-color: #0056b3;
}

/* Links Section */
/* Links Section */
.CRS-links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
  width: 70%;
}

/* Link Buttons */
.CRS-link-button {
  background: none;
  border: none;
  color: #007bff; /* Link color */
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
}

.CRS-link-button:hover {
  text-decoration: underline; /* Underline on hover */
}

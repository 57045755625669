.revision-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
}

.missing-details{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.missing-keywords,
.revision-input,
.revision-textarea {
  width: 90%; /* Adapt width to parent container */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #f8f9fa;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.revision-section .missing-keywords ul {
  list-style-type: disc;
  padding-left: 30px;
  text-align: left;
}

.revision-textarea {
  min-height: 300px;
  resize: none;
  overflow-y: hidden; /* Hide scrollbar */
}

li{
  color: rgb(127, 127, 127);
}
.revision-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  display: block;
  border-radius: 4px;
  margin: 0 auto;
}

.revision-section button:hover {
  background-color: #0056b3;
}

.Revision-Headers, .Revision-Item {
  text-align: left;
  color: rgb(127, 127, 127);
}

.GifSpin2 {
  width: 50%; /* Adjust for responsiveness */
  margin-bottom: 30px;
  max-width: 200px; /* Set a maximum width */
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .GifSpin2 {
    width: 70%; /* Larger on smaller screens */
  }

  .revision-textarea,
  .missing-keywords,
  .revision-input {
    width: 100%; /* Full width on smaller screens */
  }
}

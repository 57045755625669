/* General Styles */
.IP-InterviewPractice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  font-family: Arial, sans-serif; /* Choose your preferred font */
}

.IP-Main-Header-Interview,
.IP-Main-Header {
  text-align: center;
  margin-bottom: 20px;
}

/* Interview Tips Card */
.IP-interviewTipsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

/* Voice Bot Container */
.IP-VoiceBot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
}

/* Voice Bot Buttons */
.IP-VBButtons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

/* Voice Bot Text and Instructions */
.IP-VoiceBotSays,
.IP-instructVBClick {
  text-align: center;
  margin: 10px 0;
}

/* User Speech Textarea */
.userSpeech {
  width: 65%;
  height: 150px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

/* User Controls */
.IP-UserControls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

/* Speech Recognition Buttons */
.IP-SpeechRecButtons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

/* QA Pair Box */
.IP-Interview-Answer-Box {
  width: 100%;
  margin-top: 20px;
}

.IP-qaPair {
  background-color: #f4f4f4;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.IP-Idialog, .IP-Udialog, .IP-Submissions, .IP-Response-Time, .IP-Typed-To-Spoken-Ratio{
  color: rgb(126,126,126);
}

/* OpenAI Response Box */
.IP-openAIResponseBox {
  background-color: white;
  width: 100%;
  min-height: 25vh;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.IP-openAIResponseBoxText,
.IP-openAIResponseBoxTitle {
  color: rgb(126,126,126);
  margin: 5px;
  text-align: left;
  padding: 0 10px;
}

/* Buttons */
button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.IP-VOICEBOT {
  margin-left: -2%;
}

/* Media Query for smaller screens */
@media (min-width: 768px) {
  .userSpeech {
    width: 30%;
  }

  .IP-openAIResponseBox {
    width: 50%;
  }

  .IP-Interview-Answer-Box{
    width: 50%;
  }
}

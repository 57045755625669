/* General Styles */
.RR-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  font-family: Arial, sans-serif; /* Choose your preferred font */
}

.RR-Main-Header-Resume, .RR-Main-Header {
  text-align: center;
  margin-bottom: 20px;
}

/* Previous Work Section */
.RR-previous-work-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.RR-HideButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

/* Job Opportunities Section */
.RR-jobOps {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

/* Voice Bot and Analysis Section */
.RR-analysis-section, .RR-VoiceBot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.RR-VBButtons {
  display: flex;
  gap: 10px;
}

/* Voice Bot Text and Instructions */
.RR-VoiceBotSays {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.RR-VoiceBotTextDB {
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  word-wrap: break-word;
}

/* Final Results Section */
.RR-finalResults-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

/* Buttons */
button {
  background-color: #4c63af;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

/* Logout Nav and Custom Drawer */

.logout-nav {
  display: flex;
  justify-content: space-between; /* Or 'center' based on your design */
  align-items: center;
  position: fixed;
  top: 0;
  left: 2%;
  width: 100%;
  padding: 10px;
  z-index: 1000;
}

.custom-drawer .MuiDrawer-paper {
  color: white;
  background-color: rgba(13, 13, 13, 0.172);
}

.RR-VoiceBot-container{
  margin-left: 0px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 75%;
  padding: 20px;
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .RR-VoiceBot-container, .RR-analysis-section, .RR-jobOps, .RR-previous-work-section, .RR-finalResults-section {
    flex-direction: column;
    align-items: center;
  }

  .RR-VBButtons {
    flex-direction: row;
  }
}

/* Media Query for Larger Screens */
@media (min-width: 769px) {
  .RR-VoiceBot-container, .RR-analysis-section, .RR-jobOps, .RR-previous-work-section, .RR-finalResults-section {
    flex-direction: column;
    justify-content: space-between;
  }

  .RR-VBButtons {
    flex-direction: row;
  }

  .RR-VoiceBot-container{
    width: 25%;
  }
}

.PRV-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 2rem;
  max-width: 800px;
  margin: auto;
  color: #e9e9e9; /* Darker text for readability */
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.PRV-Header {
  font-size: 2.5rem;
  margin-top: 0;
  color: #e9e9e9; /* Brand color for headers */
}

.PRV-Paragraph {
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.PRV-ParaHeader {
  font-size: 1.75rem;
  color: #e9e9e9; /* Brand color for subheaders */
  margin-top: 2rem;
}

.PRV-UnorderedList {
  list-style: inside square; /* Stylish bullet points */
}

.PRV-ListItem {
  margin-bottom: 0.5rem;
}


.PRV-links-section{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px; /* Space between links */
  margin-top: 30px;
}

.PRV-cta-button {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.PRV-link-button,
.PRV-cta-button {
  background: none;
  border: none;
  color: #007bff; /* Link color */
  cursor: pointer;
  font-size: 1rem; /* Adjust font size */
}

.TOS-links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px; /* Space between links */
  margin-top: 30px;
}

.PRV-LINK{
  color: #007bff
}

.PRV-link-button:hover,
.PRV-cta-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

@media (max-width: 768px) {
  .PRV-Container {
    padding: 1rem;
    max-width: 100%;
  }

  .PRV-Header,
  .PRV-ParaHeader {
    font-size: 1.5rem;
  }

  .PRV-Paragraph {
    font-size: 1rem;
  }
}

/* General Admin Section Styles */
.admin-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: Arial, sans-serif; /* Choose your preferred font */
  width: 100%;
}

/* Header Styles */
.admin-Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.CR-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.adminTools {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.AD-Main-Header-Title {
  padding-top: 50px;
}

.AD-Main-Header-Tagline {
  text-align: center;
  margin-bottom: 20px;
}

/* Admin Tools Section */
.AdminTools {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  gap: 20px; /* Spacing between columns */
}

.Base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

/* Chart and Response Section */
.AdminTools-Question-Chart,
.QuestionResponsesByDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500%; /* Set a max width */
  margin: 0 auto;
  padding: 30px;
}

.ReasonToChangeTokens {
  width: 80%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.QuestionData {
  width: 100%;
  height: 300px; /* Adjust as needed */
}

/* Buttons and Inputs */
button,
.RoleSelector,
.Select-Device-UserID {
  background-color: #2179b8;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  width: auto; /* Adjust width as needed */
}

.SelectQuestionDevice {
  background-color: #2179b8;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%; /* Adjust width as needed */
}

/* Token and Role Management Section */
.Tools-Holder,
.token-increment-section,
.UserRoleManagement,
.RoleSelection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.IncrementTokens-Title,
.IncrementTokens-Title-Small {
  margin: 10px 0 10px 0;
}

.Select-User-Role-Title,
.Select-User-Role {
  margin: 2px 0 0 0;
}

.Title-Header,
.Type-Header,
.User-Responses-Title,
.ColorChanger-Title,
.IncrementTokens-Title,
.Select-User-Role-Title {
  font-size: 1.2rem;
  margin-top: 30px;
}

/* Modal and Button Box Styles */
.Modal,
.button-Box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
.spacer {
  width: 25%;
}

.Tools-Holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.response {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #e9e9e9;
  width: 50%;
}
/* Responsive Design */
@media (max-width: 768px) {
  .AdminTools {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center;
  }

  .AdminTools-Question-Chart,
  .QuestionResponsesByDate {
    width: 90%; /* Adjust width for smaller screens */
  }

  .button-Box {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
    gap: 10px;
  }
}

@media (min-width: 768px) {
  .AdminTools {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border: 1px solid #7c7c7c;
    border-radius: 4px;
    width: 100%;
  }

  .AdminTools-Question-Chart,
  .QuestionResponsesByDate {
    max-width: 35%;
  }

  .AdminTools-Question-Chart {
    width: 60%; /* Adjust width for smaller screens */
  }

  .QuestionResponsesByDate {
    width: 100%; /* Adjust width for smaller screens */
    border: 1px solid #ccc;

    padding: 0px;
    margin: 0 0 0 0;
  }

  .Tools-Holder {
    width: 50%;
    margin-top: 0px;
  }

  input[type="number"] {
    width: 20%;
  }
}

.SlideShow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 150px;
  padding-top: 5vh; /* Adjust top padding */
  width: 100%;
  background-color: rgb(238, 55, 55, 0.25);
}

.SS-supheader-title {
  text-align: center;
  font-size: 3rem; /* Adjust font size */
  margin-top: 0;
  margin-bottom: 20px;
}

.SS-tagline {
  font-size: 1.5rem; /* Adjust font size */
  margin-bottom: 20px;
}

.SS-Buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.SS-projectImages{
    border: 4mm ridge rgba(211, 220, 50, .6);
}

/* Link Buttons */
.SS-links-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Space between buttons */
    margin-top: 20px;
    width: 70%;
  }
  
  /* Link Buttons */
  .SS-link-button {
    background: none;
    border: none;
    color: #007bff; /* Link color */
    font-size: 1rem;
    padding: 10px;
    cursor: pointer;
  }
/* Contact Us Container */
.CU-ContactUs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  width: 100%;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #e3e3e3; /* Adjust text color */
}

/* Header Title and Tagline */
.CU-header-title {
  margin-bottom: 20px;
}

.CU-tagline {
  width: 75%;
  margin-bottom: 20px;
  word-wrap: break-word;
}

/* Contact Form */
.CU-contact-form {
  display: flex;
  flex-direction: column;
  width: 350px;
}
input[type="email"]{
  width: 95%;
}

.CU-contact-form label {
  margin-top: 10px;
}

.CU-contact-form input,
.CU-contact-form textarea {
  
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.CU-contact-form textarea {
  height: 200px; /* Adjust height as needed */
}

/* Submit Button */
.CU-contact-form button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; /* Adjust font size as needed */
}

.CU-contact-form button:hover {
  background-color: #0056b3;
}



/* Links Section */
.CU-links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
  width: 70%;
}

/* Link Buttons */
.CU-link-button {
  background: none;
  border: none;
  color: #007bff; /* Link color */
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
}

.CU-link-button:hover {
  text-decoration: underline; /* Underline on hover */
}

@media (min-width: 768px) {
  .CU-login-header-title {
    font-size: 3rem; /* Larger font for larger screens */
  }

  .CU-tagline {
    font-size: 1.5rem; /* Adjusting tagline font size */
  }

  .CU-contact-form {
    display: flex;
    flex-direction: column;
    width: 600px;
  }

  input[type="email"]{
    width: 97%;
  }

}

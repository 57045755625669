/* Menu Container */
.PGEX-Menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Adjust height as needed */
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #333; /* Adjust text color */
}

/* Header Title and Tagline */
.PGEX-header-title {
  font-size: 3rem; /* Adjust font size */
  margin-bottom: 20px;
}

.PGEX-tagLine {
  font-size: 1.5rem; /* Adjust font size */
  margin-bottom: 20px;
}

/* Task Menu for Page Expired */
.PGEX-Task-Menu {
  font-size: 2rem; /* Adjust font size */
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Button Holder */
.PGEX-BTN-HLDR {
  display: flex;
  flex-direction: row; /* Adjust direction */
  align-items: center;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
}

/* Buttons */
.PGEX-Menu-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; /* Adjust font size */
}

.PGEX-Menu-container button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 767px) {
  .PGEX-BTN-HLDR {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
  }
}

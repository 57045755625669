#wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-height: 320px;
  width: 90%;
}

#theBot {
  width: 650px;
  min-height: 570px;
  transform: scale(0.5);
  border: 5px;
  translate: 30px;
}

.VoiceBotSetting {
  display: flex;
  flex-direction: column;
  position: relative;
}

.tooltip {
  display: none;
  font-size: 0.5rem;
  color: white;
  position: absolute; /* Use absolute positioning within the parent container */
  top: 73.5%; /* Adjust to your desired vertical position */
  left: 50.5%; /* Horizontally center */
  transform: translate(-50%, -50%); /* Center the tooltip */
  flex-direction: row; /* Display the text side by side */
  align-items: center; /* Vertically center text */
  justify-content: center; /* Horizontally center the content */
  background-color: rgba(255, 142, 142, 0.267);
  border: 1.5px solid black;
  border-radius: 8px;
  padding: 5px;
  z-index: 9999;
  width: 120px; /* Fixed width to prevent shifting */
  white-space: nowrap;
  text-align: center; /* Ensure text is centered */
}

.tooltip .static-text {
  flex-shrink: 0; /* Prevent the "Phoneme:" part from shrinking */
}

.tooltip .dynamic-text {
  font-weight: bold; /* Make the digits stand out */
  min-width: 30px; /* Minimum width for the number, prevents text from jumping */
  text-align: center; /* Ensure the number aligns properly */
}

.show-tooltip {
  display: flex; /* Flexbox to make the tooltip visible */
}



@media (min-width: 768px) {
  #wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 90%;
    height: 100%;
  }

}

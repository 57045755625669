/* Style for the container */
.table-container {
  overflow-x: auto;
  max-width: 100%;  /* Allow container to resize, but limit width */
}

/* General table style */
.viseme-table {
  width: auto;
  border-collapse: collapse;
  table-layout: fixed;  /* Force fixed column and row sizes */
}

.saveVisemeImage{
  font-size: 10px;
}

/* Style for all table cells */
.viseme-table td, .viseme-table th {
  padding: 5px;    /* Reduced padding for smaller cells */
  text-align: center;
  border: 1px solid #ddd;
  font-size: 10px;  /* Reduced font size */
 
  transition: background-color 0.5s, color 0.5s; /* Smooth transition for glowing effect */
}

/* Toned down background for headers */
.viseme-table th {
  background-color: rgba(244, 174, 174, 0.5); /* Subtle gray */
  color: black;  /* Ensure the text is visible */
  font-size: 10px;  /* Make header text smaller */

}

/* Glowing effect */
.viseme-table td.glow {
  background-color: rgba(181, 67, 209, 0.107);
  animation: glow 1s infinite;
}

/* Glow animation */
@keyframes glow {
  0% {
    background-color: rgba(103, 180, 234, 0.8);
  }
  50% {
    background-color: rgb(163, 169, 79);
  }
  100% {
    background-color: rgba(242, 103, 103, 0.806);
  }
}

/* GoogleSearch Component Styles */
.google-search-container {
    width: 100%;
    height: 420px;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(133, 133, 133, 0.3); /* Light background */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .google-search-title {
    font-size: 2rem;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .google-search-form {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .google-search-input {
    width: 70%;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .google-search-input:focus {
    border-color: #007bff;
  }
  
  .google-search-button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .google-search-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .google-search-button:hover {
    background-color: #0056b3;
  }
  
  .google-search-error {
    color: red;
    text-align: center;
    margin-top: 20px;
  }
  
  .google-search-results {
    margin-top: 30px;
  }
  
  .google-search-results-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .google-search-result-item {
    padding: 15px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .google-search-result-item:last-child {
    border-bottom: none;
  }
  
  .google-search-result-link {
    font-size: 1.2rem;
    color: #007bff;
    text-decoration: none;
  }
  
  .google-search-result-link:hover {
    text-decoration: underline;
  }
  
  .google-search-result-snippet {
    font-size: 1rem;
    color: #555;
    margin-top: 5px;
  }
  
  .google-search-result-image {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
  }
  
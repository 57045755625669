/* InputForm.css */

/* Container for the whole form */
.container {
  display: flex;
  flex-direction: column;
  max-width: 90%;  /* Allow for responsive resizing */
  margin: 0 auto;
  padding: 20px;
  overflow-x: auto;  /* Allow horizontal scroll if needed */
}

/* Each row container - stacks vertically and groups inputs */
.row-container {
  display: flex;
  flex-direction: column;  /* Arrange the fields vertically */
  margin-bottom: 20px;
  background-color: rgba(240, 240, 240, 0.25); /* Light background color with transparency */
  padding: 15px;
  border-radius: 10px;  /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01);
}

/* Style for input group (inputs in a row) */
.input-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

/* Style for each input field */
.IF-input, select {
  margin: 5px;
  padding: 8px 12px; /* Reduced padding for compactness */
  font-size: 14px;
  width: 100%; /* Set to 100% to ensure it fills the container */
  box-sizing: border-box; /* Ensures padding is included in width */
  border: none; /* Remove borders */
  border-radius: 4px; /* Rounded corners */
  background-color: #f9f9f9; /* Slight background for input fields */
  transition: background-color 0.3s ease; /* Smooth transition on focus */
}

/* Adjustments for inputs with smaller size (for Start Time and Stop Time) */
.small-input {
  width: 70%; /* Smaller width to make room for the button */
  margin-right: 8px; /* Space between input and button */
}

/* Focus State for Inputs */
.IF-input:focus {
  outline: none; /* Remove default focus outline */
  background-color: #e0f7fa; /* Light background on focus */
  box-shadow: 0 0 3px rgba(0, 150, 136, 0.5); /* Subtle focus shadow */
}

/* Style for Text Input (textarea) */
.IF-input.text-field {
  min-width: 420px;  /* Set minimum width */
  min-height: 250px;
  height: 50px;      /* Set height to make it larger */
  padding: 10px;
  resize: vertical;  /* Allow vertical resizing */
  overflow-y: auto;   /* Add scrolling */
  vertical-align: top; /* Align text to the top */
  white-space: pre-wrap; /* Ensure text wraps properly */
  word-wrap: break-word; /* Allow words to break and wrap within the field */
}

/* Label styling */
.label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  width: 100%;
  display: block;
}

/* Add button styling */
.add-button {
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

/* Remove button styling */
.remove-button {
  margin-left: 170px;
  padding: 5px 10px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 30%;
}

/* Row number styling */
.row-number {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-left: 5px;
  margin-bottom: 15px;
  width: 98%; /* Adjust the width if necessary */
  display: inline-block; /* Makes it inline with other items */
  text-align: center;
  padding: 5px;
  background-color: #f0f0f0;
}



/* Container for the form fields - handles overflow */
.scrollable-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  padding: 10px;
}

/* Button Styling for Capture Time */
.capture-time-button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 5px; /* Space between input and button */
}

.capture-time-button:hover {
  background-color: #00796b; /* Darker teal on hover */
}

/* Container for input and button (to align them horizontally) */
.input-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px; /* Space between the input and button */
}

/* Scrollable Container */
.scrollable-container {
  max-height: 500px;
  overflow-y: auto;
}

/* Add Task Button */
.add-button {
  background-color: #4caf50; /* Green background */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #388e3c; /* Darker green on hover */
}

/* Small input field to make room for the button */
.small-input {
  width: calc(90% - 5px);  /* Adjust width to leave space for the button */
}

/* DocumentModal.css */

.document-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.document-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #74ade66b;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 90%;
  text-align: left;
  overflow-y: auto;
  max-height: 80%;
}

.documents-modal-container {
  display: flex;
  width: 100%;
  height: 90%;
  border-radius: 4px;
  justify-content: space-around;
  margin-bottom: 20px;
}

.document-modal-section {
  flex: 0 0 45%;
  margin: 0 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #eef3f8;
  padding: 10px;
  overflow-y: auto;
  text-align: left;
}

.document-modal-section h3 {
  color: rgb(126, 126, 126);
}

p, .Cover-Letter, .Resume{
  white-space: pre-wrap;
  font-size: 16px;
  margin: 10px 0;
  color: rgb(126, 126, 126);
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.DocModalButtonBottom{
  margin-top: px;
}

.DocModal {
  background: #2280df;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button {
  margin: 5px 10px;
  background: #2280df;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background: #1565c0;
}

/* Responsive layout for larger screens */
@media (min-width: 768px) {
  .documents-modal-container {
    flex-direction: row;
  }
  .document-modal-section {
    flex: 1 1 45%; /* Adjust the flex basis to 45% */
  }
}

/* Responsive layout for smaller screens */
@media (min-width: 768px) {
  .DocModal {
    max-width: 200px; /* Set a maximum width for the buttons */
    margin: 10px auto; /* Center the buttons with automatic margin */
  }
}
@media (max-width: 767px) {
  .documents-modal-container {
    flex-direction: column;
  }

  .document-modal-section {
    /* Add margin to separate the sections */
    margin-bottom: 20px;
  }
}

/* Button container adjustments */
.buttons-container {
  flex-wrap: wrap; /* Wrap buttons if needed */
  justify-content: space-around;
  align-items: center;
  width: 80%;
}

/* Button styling */
.DocModal {
  margin: 10px; /* Add margin to provide spacing between buttons */
  flex: 1 0 auto; /* Allow buttons to grow and shrink as needed */
}

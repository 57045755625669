.results-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.ats-score {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  display: inline-block;

}

.keywords-container {
  width: 100%; /* Adjusted width */
  margin: 30px auto 20px; /* Centered with auto margins */
}

.results-ats-score {
  display: flex;
  flex-direction: column; /* Stack elements on top of each other on smaller screens */
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media (min-width: 769px) {
  .results-section {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .results-ats-score {
    flex-direction: row; /* Side by side on larger screens */
  }

  .ats-score,
  h3 {
    width: 100%;
    text-align: center;
  }

  .ats-score {
    width: auto;
    margin: 0 auto;
  }

  .keywords-container {
    width: 75%; /* Adjusted for larger screens */
    display: flex;
    flex-direction: row; /* Stack elements on top of each other on smaller screens */
    align-items: center;
    justify-content: center;
  }
}

.keywords{
  width: 90%;
}
/* Menu Container */
.MU-menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
}



/* Header Title */
.MU-menu-header-title {
  text-align: center;
  font-size: 3rem; /* Adjust font size */
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Tagline */
.MU-tagLine {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
}

/* Voice Bot Container */
.MU-VoiceBot-container {
  width: 100%; /* Adjust width as needed */
  height: 350px;
}

/* Task Menu Title */
.MU-Task-Menu {
  text-align: center;
  font-size: 1.0rem; /* Adjust font size */
  margin-bottom: 20px;
}

/* Button Holder */
.MU-BTN-HLDR {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center;
  gap: 10px; /* Space between buttons */
  margin-bottom: 20px;
}

/* Buttons */
.MU-BTN-HLDR button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  width: 250px; /* Fixed width for buttons */
}

/* Responsive Design for Larger Screens */
@media (min-width: 768px) {
  .MU-menu-container {
    padding-top: 5vh; /* Adjust top padding */
  }

  .MU-menu-header-title {
    font-size: 4rem; /* Larger font for larger screens */
  }

  .MU-Task-Menu {
    font-size: 2.5rem; /* Adjust font size */
  }
}

/* Login Container */
.LI-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #333; /* Adjust text color */

}

/* Headers and Taglines */
.LI-login-header-title, .LI-tagline, h3 {
  text-align: center;
  margin-bottom: 20px;
}

/* Form Styling */
form.LI-Login-Input {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 500px; /* Maximum width */
  margin: 20px auto; /* Centering and spacing */
}

/* Input Fields */
.LI-input-email[type="email"], .LI-input-password[type="password"] {
  width: 90%; /* Full width for smaller screens */
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Button Container */
.LI-button-container {
  text-align: center;
  width: 90%;
}

/* Buttons */
button.LI-login-button, button.LI-google-sign-in-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 4px;
  width: 60%; /* Adjust width as needed */
}

.LI-google-sign-in-button img {
  margin-right: 8px;
  width: 20px; /* Adjust the size of the Google logo */
  height: 20px; /* Adjust the size of the Google logo */
}

/* Error Message */
.LI-error-message {
  color: rgb(243, 97, 97);
  background-color: #fff3f3; /* Light red background for visibility */
  border: 1px solid #f36161;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

/* Google Sign In Button */
.LI-google-sign-in-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between text and image */
}

/* Link Styling */
.LI-signup-link, .LI-pwReset-link {
  color: #f7e14f; /* Adjust link color */
  text-decoration: none;
}

.LI-signup-link:hover, .LI-pwReset-link:hover {
  color: #f69d29;
  text-decoration: underline; /* Underline on hover */
}

/* Links Section */
.links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
  width: 70%;
}

/* Individual Link Buttons */
.link-button {
  background: none;
  border: none;
  color: #007bff; /* Link color */
  font-size: 1rem;
  padding: 10px;
  cursor: point
}

/* Responsive Design for Larger Screens */
@media (min-width: 768px) {
  form.LI-Login-Input {
    width: 50%; /* Wider form for larger screens */
  }

  .LI-login-header-title {
    font-size: 3rem; /* Larger font for larger screens */
  }

  .LI-tagline {
    font-size: 1.5rem; /* Adjusting tagline font size */
  }

  button.LI-google-sign-in-button {
    width: 16%;
  }
}

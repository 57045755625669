/* Tokens Container */
.TO-Tokens-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Adjust height as needed */
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #e9e9e9; /* Adjust text color */
}

/* Header Title and Tagline */
.TO-header-title, .TO-tagline {
  font-size: 3rem; /* Adjust font size for header */
  margin-bottom: 20px;
}

.TO-tagline {
  font-size: 1.5rem; /* Adjust font size for tagline */
  margin-bottom: 20px;
}

/* Input for Token Number */
.TO-TokenNum, input[type="number"] {
  width: 10%; /* Adjust width as needed */
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem; /* Adjust font size */
}

/* Call to Action Button */
.TO-cta-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; /* Adjust font size */
  margin-bottom: 20px;
}

.TO-cta-button:hover {
  background-color: #0056b3;
}

/* Under Construction Text */
.TO-under-construction-text {
  font-size: 2rem; /* Adjust font size */
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Div Message */
.TO-divMsg {
  width: 90%; /* Width adjustment */
  margin-bottom: 20px;
  font-size: 1.25rem; /* Adjust font size */
}

/* Links Section */
.TO-links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
  width: 70%;
}

/* Link Buttons */
.TO-link-button {
  background: none;
  border: none;
  color: #007bff; /* Link color */
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
}

.TO-link-button:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Responsive Design */
@media (min-width: 767px) {
  .TO-TokenNum, input[type="number"] {
    width: 3%; /* Full width for smaller screens */
  }
}
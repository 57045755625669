/* Not Found Container */
.NOFO-not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Adjust height as needed */
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #333; /* Adjust text color */
}

/* Not Found Text */
.NOFO-not-found-text {
  font-size: 3rem; /* Adjust font size */
  margin-bottom: 20px;
}

.NOFO-not-found-container p {
  font-size: 1.5rem; /* Adjust font size */
  margin-bottom: 20px;
}

/* Links Section */
.NOFO-links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between links */
  margin-top: 20px;
}

.NOFO-links-section a {
  color: #007bff; /* Link color */
  text-decoration: none;
  font-size: 1rem; /* Adjust font size */
}

.NOFO-links-section a:hover {
  text-decoration: underline; /* Underline on hover */
}

.NOFO-Paragraph {
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.NOFO-LINK{
  color: #007bff
}
/* Landing Container */
.HP-landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif; /* Choose your preferred font */
  color: #333; /* Adjust text color */
}

/* Header Title */
.HP-conversation-header-title {
  margin-top: 20px;
  font-size: 3rem; /* Adjust font size */
  margin-bottom: 20px;
}

/* Tagline */
.HP-tagline {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

/* Call to Action Button */
.HP-cta-button {
  padding: 10px 20px;
  font-size: 1.25rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.HP-cta-button:hover {
  background-color: #0056b3;
}

/* New Section */
.HP-new-section {
  width: 100%;
  margin-top: 2rem;
}

/* Row Styling */
.HP-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.HP-row-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  width: 150px;
}

/* Item Styling */
.HP-item,
.HP-itema,
.HP-middle-item {
  display: flex;
  font-size: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background-color: transparent;
  border: 2px solid #007bff; /* Adjust border color */
  margin: 10px 10px;
  width: 50%;
  height: 230px;
  background-color: rgba(0, 0, 0, 0.208);
  box-shadow: 10px 10px 30px rgb(255, 255, 214);
}

.HP-OR {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background-color: transparent;
  margin: 10px 10px;
  width: 10%;
  height: 20px;
  color: white;
}


/* Arrow Styling */
.HP-arrow {
  font-size: 3rem; /* Adjust font size */
  margin-top: 20px;
}

/* Image Container */
.HP-image-container {
  width: 100%; /* Adjust width as needed */
  margin-top: 20px;
  height: 200px;         /* Adjust the height to control the slice size */
  overflow: hidden;
  position: relative;
  border-radius: 5px; /* Rounded corners */
}

/* Image Styling */
.HP-unsplash-image {
  width: 100%;
  height: auto;
  top: 50%; /* Align the middle of the image with the container */
  transform: translateY(-17%); /* Shift the image up by half its height */
  opacity: 0.5;
}

/* Link Section */
.HP-links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
}

/* Link Buttons */
.HP-link-button {
  background: none;
  border: none;
  color: #007bff; /* Link color */
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
}

.HP-link-button:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .HP-row-2 {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .HP-row {
    flex-direction: row;
  }
  .HP-itema{
    width: 10%;
  }
}
